/* First Image */

.verticalGiftVisible {
    animation: 1s verticalGiftAppear;
    animation-fill-mode: forwards;
    animation-delay: 1s;
    transform: translateY(100%);
}

.verticalGiftInvisible {
    animation: 1s verticalGiftDisappear;
    animation-fill-mode: forwards;
}

@keyframes verticalGiftAppear {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0%);
    }
}

@keyframes verticalGiftDisappear {
    from {
        transform: translateY(0%);
    }

    to {
        transform: translateY(100%);
    }
}

.horizontalGiftVisible {
    animation: 0.5s horizontalGiftAppear;
    animation-fill-mode: forwards;
    animation-delay: 1s;
    transform: translateY(100%);
}

.horizontalGiftInvisible {
    animation: 0.5s horizontalGiftDisappear;
    animation-fill-mode: forwards;
}

@keyframes horizontalGiftAppear {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0%);
    }
}

@keyframes horizontalGiftDisappear {
    from {
        transform: translateY(0%);
    }

    to {
        transform: translateY(100%);
    }
}

.leaves1Visible {
    animation: 2s leaves1Appear;
    animation-fill-mode: forwards;
    animation-delay: 0.5s;
    transform: translateY(100%);
    opacity: 0;
}

.leaves1Invisible {
    animation: 1.5s leaves1Disappear;
    animation-fill-mode: forwards;
}

@keyframes leaves1Appear {
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    75% {
        opacity: 0.2;
    }

    to {
        transform: translateY(0%);
        opacity: 1;
    }
}

@keyframes leaves1Disappear {
    from {
        transform: translateY(0%);
        opacity: 1;
    }

    to {
        transform: translateY(100%);
        opacity: 0;
    }
}

.personMiddleVisible {
    animation: 1s personMiddleAppear;
    animation-fill-mode: forwards;
    animation-delay: 0.5s;
    opacity: 0;
}

.personMiddleInvisible {
    animation: 1s personMiddleDisappear;
    animation-fill-mode: forwards;
}

@keyframes personMiddleAppear {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes personMiddleDisappear {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

/* Second Image */

.squareGiftVisible {
    animation: 1s squareGiftAppear;
    animation-fill-mode: forwards;
    animation-delay: 1s;
    transform: translateY(100%) translateY(-36px);
}

.squareGiftInvisible {
    animation: 1s squareGiftDisappear;
    animation-fill-mode: forwards;
}

@keyframes squareGiftAppear {
    from {
        transform: translateY(100%) translateY(-36px);
    }

    to {
        transform: translateY(0%) translateY(-36px);
    }
}

@keyframes squareGiftDisappear {
    from {
        transform: translateY(0%) translateY(-36px);
    }

    to {
        transform: translateY(100%) translateY(-36px);
    }
}

.personLeftVisible {
    animation: 1.5s personLeftAppear;
    animation-fill-mode: forwards;
    animation-delay: 1s;
    opacity: 0;
    transform: translateX(-100%) translateY(-36px);
}

.personLeftInvisible {
    animation: 1s personLeftDisappear;
    animation-fill-mode: forwards;
}

@keyframes personLeftAppear {
    from {
        opacity: 0;
        transform: translateX(-100%) translateY(-36px);
    }

    to {
        opacity: 1;
        transform: translateX(0%) translateY(-36px);
    }
}

@keyframes personLeftDisappear {
    from {
        opacity: 1;
        transform: translateX(0%) translateY(-36px);
    }

    to {
        opacity: 0;
        transform: translateX(-100%) translateY(-36px);
    }
}

.personRightVisible {
    animation: 1s personRightAppear;
    animation-fill-mode: forwards;
    animation-delay: 0.5s;
    opacity: 0;
    transform: translateX(200%) translateY(-36px);
}

.personRightInvisible {
    animation: 1s personRightDisappear;
    animation-fill-mode: forwards;
}

@keyframes personRightAppear {
    from {
        opacity: 0;
        transform: translateX(200%) translateY(-36px);
    }

    to {
        opacity: 1;
        transform: translateX(0%) translateY(-36px);
    }
}

@keyframes personRightDisappear {
    from {
        opacity: 1;
        transform: translateX(0%) translateY(-36px);
    }

    to {
        opacity: 0;
        transform: translateX(200%) translateY(-36px);
    }
}

.leaves2Visible {
    animation: 1.5s leaves2Appear;
    animation-fill-mode: forwards;
    animation-delay: 1s;
    transform: translateY(100%);
    opacity: 0;
}

.leaves2Invisible {
    animation: 5s leaves2Disappear;
    animation-fill-mode: forwards;
    animation-delay: 0.5s;
    transform: translateY(-10%);
}

@keyframes leaves2Appear {
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    75% {
        opacity: 0.5;
    }

    to {
        transform: translateY(-10%);
        opacity: 1;
    }
}

@keyframes leaves2Disappear {
    from {
        transform: translateY(-10%);
        opacity: 1;
    }

    to {
        transform: translateY(100%);
        opacity: 0;
    }
}

.squiggleVisible {
    animation: 1s squiggleAppear;
    animation-fill-mode: forwards;
    animation-delay: 3s;
    opacity: 0;
    transform: translateY(-36px);
}

.squiggleInvisible {
    animation: 1s squiggleDisappear;
    animation-fill-mode: forwards;
    transform: translateY(-36px);
}

@keyframes squiggleAppear {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes squiggleDisappear {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.balloon1Visible {
    animation: 1s balloon1Appear;
    animation-fill-mode: forwards;
    animation-delay: 2s;
    transform: translateY(-60px);
}

.balloon1Invisible {
    animation: 0.5s balloon1Disappear;
    animation-fill-mode: forwards;
}

@keyframes balloon1Appear {
    from {
        transform: translateY(-60px);
    }

    80% {
        transform: translateY(10px);
    }

    to {
        transform: translateY(0px);
    }
}

@keyframes balloon1Disappear {
    from {
        transform: translateY(0px);
    }

    to {
        transform: translateY(-60px);
    }
}

.balloon2Visible {
    animation: 2s balloon2Appear;
    animation-fill-mode: forwards;
    animation-delay: 1.8s;
    transform: translateY(-145px);
}

.balloon2Invisible {
    animation: 0.7s balloon2Disappear;
    animation-fill-mode: forwards;
}

@keyframes balloon2Appear {
    from {
        transform: translateY(-145px);
    }

    80% {
        transform: translateY(1px);
    }

    to {
        transform: translateY(-36px);
    }
}

@keyframes balloon2Disappear {
    from {
        transform: translateY(-36px);
    }

    to {
        transform: translateY(-145px);
    }
}

.balloon3Visible {
    animation: 2s balloon3Appear;
    animation-fill-mode: forwards;
    animation-delay: 1.5s;
    transform: translateY(-137px);
}

.balloon3Invisible {
    animation: 1s balloon3Disappear;
    animation-fill-mode: forwards;
}

@keyframes balloon3Appear {
    from {
        transform: translateY(-137px);
    }

    80% {
        transform: translateY(10px);
    }

    to {
        transform: translateY(-36px);
    }
}

@keyframes balloon3Disappear {
    from {
        transform: translateY(-36px);
    }

    to {
        transform: translateY(-137px);
    }
}
